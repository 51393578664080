import { Patterns } from "./patterns";
import { useDispatch, useSelector } from "react-redux";
import { map as m, prop as p, last, compose as C, __, path, includes, filter as f, reverse, reject,
   equals as eq, prepend, dropLast, reduce,identity as id
} from "ramda";
import { text_pos, add_text, dot_pos, add_dot, change_line, add_line, title_pos, add_title,
  add_marker, marker_pos, move_header, add_header, end_bars, bar_pos, add_bars, score_pos,
  add_scores,move_title_bg,title_bg
} from "./join_fns";
import * as d3 from "d3";
import { useEffect, useMemo, useRef, useState } from "react";
import { set_priority, set_selected, toggle_columns, toggle_panel } from "../../app/ui_state";
import { add_clickevent, appear, create, transition } from "../helpers";
import { useColumnsQuery, useConnectionsQuery, useItemsQuery } from "../../app/api";
import { group_spacers, visual_columns, height, texts, bars, groups, points, 
  active, links 
} from "../../app/functions";
import "./visual.css"
import { createSelector } from "@reduxjs/toolkit";

export const Visual = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [svg_height,setHeight] = useState(0);
  const [svg_width,setWidth] = useState(0);

  const ui = useSelector(path(["ui"]));

  const { data:items } = useItemsQuery();
  const { data:connections } = useConnectionsQuery();
  const memoized_main_data = useMemo(() => {
  },[useItemsQuery,useConnectionsQuery]) 

  const panel = useSelector(path(["ui","panel_open"]));
  const width_dependent = useSelector(path(["ui","width_dependent"]));
 
  const { data:columns } = useColumnsQuery();
  const opened = useSelector(path(["ui","opened"]));

  const selected = useSelector(path(["ui","selected"]));

  useEffect(() => {
    if (items && connections && columns) {
      const visual_data = reduce((acc,[key,fn]) => {
        return ({...acc,[key]:fn(
          {connections,items,columns},
          {...ui,width_dependent,opened,selected},
          acc)})
      },{},[
        ["group_spacers",group_spacers],
        ["visual_columns",visual_columns],
        ["height",height],
        ["points",points],
        ["active",active],
        ["texts",texts],
        ["links",links],
        ["bars",bars],
        ["groups",groups]   
      ])  

      setHeight(visual_data.height);
      setWidth(width_dependent.width);
  
      const svg = d3.select(ref.current);
      const set_active = (e, d) => {
        const manual = ui.width_dependent.w_mode > 2; 
        if (manual) dispatch(set_priority(C(prepend(d.type),reject(eq(d.type)))(ui.priority)));
        dispatch(set_selected(d))
        if (!d.desc && !d.comment) {
          dispatch(toggle_panel(false));
        } else if(d.id === selected.id) {
          dispatch(toggle_panel())
        } 
      };
  
      const toggle_column = (e, d) => {
        const manual = ui.width_dependent.w_mode > 3       
        const open = includes(d.column,opened[0]);
        const [add_to,remove_from] = open ? reverse(opened) : opened;
        const new_b = C(reject(eq(d.column)),manual ? id : prepend(last(add_to)))(remove_from);
        const new_a = C(manual ? id : dropLast(1),prepend(d.column))(add_to);
        dispatch(toggle_columns(open ? [new_b,new_a] : [new_a,new_b]));
      };

      console.log(visual_data);

      const elements = [
        {id:"#texts",el:"text",data: visual_data.texts,add:appear(add_text,0),edit:transition(text_pos,0),click:set_active},
        {id:"#texts",el:"circle",data: visual_data.points,add:add_dot,edit:transition(dot_pos,0),click:set_active},
        {id:"#columns",el:"text",data:visual_data.visual_columns,add:add_header,edit:transition(move_header,0),click:toggle_column},
        
        {id:"#lines",el:"path",data: visual_data.links, add:add_line,edit:transition(change_line,0)},
        {id:"#titles",el:"rect",data: visual_data.groups,add:title_bg,edit:transition(move_title_bg,0)},
        {id:"#titles",el:"text",data: visual_data.groups,add:add_title,edit:title_pos},
        {id:"#selected",el:"use",data:visual_data.active,add:appear(add_marker,0),edit:marker_pos},
        
        {id:"#scores",el:"rect",data:visual_data.bars[0],add:add_bars,edit:transition(bar_pos,0)},
        {id:"#scores",el:"path",data:visual_data.bars[1],add:end_bars,edit:transition(end_bars,0)},
        {id:"#scores",el:"text",data:visual_data.bars[2],add:add_scores,edit:transition(score_pos,0)},
      ]
      m(create(svg), elements);
      m(add_clickevent(svg), f(p("click"), elements));
    }

  }, [connections,items,columns,selected,width_dependent,opened,ui]);

  return <div style={{right: panel ? 530 : 0}} id="connections">
    <svg ref={ref} height={svg_height}
      viewBox={`0 0 ${svg_width} ${svg_height}`} 
      preserveAspectRatio="xMinYMin slice">
      <Patterns/>
      <g id="lines"></g>
      <g id="texts"></g>
      <g id="scores"></g>
      <g id="columns"></g>
      <g id="selected"></g>
      <g id="titles"></g>
    </svg>
  </div>;
};
