import {compose as C,map as m,filter as f,propEq,prop as p,values,groupBy as g,
  length as len,juxt,uniq,includes,__,sort,addIndex,ascend,mean,of,toPairs,indexBy,head,
  last,apply,subtract,defaultTo,repeat,chain,concat,flatten,reduce,maxBy,sum,
  intersection,partition, multiply, append, tap, identity as id, eqProps, equals as eq, path, sortBy, indexOf, reverse, objOf, clamp
} from "ramda"
import { create_path, r, repeat_fn, rounded_rect } from "../features/helpers";

const problems = f(propEq("type","problems"));

export const visual_columns = ({columns=[]},
  {opened:[open_columns],width_dependent:{score_w,line_w,space,margin},space_top,text_w,color}) => {
  return C(
  addIndex(m)((x,n,xs) => {
    const open = includes(x.column,open_columns);
    const dx = margin+ score_w+ n*line_w + len(intersection(xs.slice(0,n).map(p("column")),open_columns))*text_w;
    const translate = (dx+(open ? text_w/2 : 5))+","+(open ? space_top-2.4*space : space_top-4.2*space);
    return {...x,x:dx,fill:color.blue,
      transform: "translate("+translate+")"+(open ? "" : " rotate(-90)")}}),
  sort(ascend(p("id"))),
)(columns)}

export const height = ({items=[]},{space_top,width_dependent:{margin,space},
  group_space},{group_spacers}) => C(
  sum,concat([margin,space_top]),
  juxt([
    C(
      multiply(space),sum,flatten,
      juxt([C(values,m(__,group_spacers),p,head),last]),
      tap(C(console.log,objOf("height"))),
      reduce(maxBy(last),[0,0]),
      toPairs,m(len),g(p("type"))),
    C(
      multiply(group_space),
      len,uniq,m(p("field")),
      problems
    )])
)(items)


export const group_spacers = ({items = []}) => C(
  addIndex(m)((x,n,xs) => ({
    ...addIndex(m)(
      (y,yn,ys) => n && clamp(0,Infinity,Math.max(...values(ys))-y),
      n > 0 ? values(xs)[n-1]: x)})),
  m(C(m(len),g(p("type")))),
  g(p("field"))
)(items)


export const points = ({connections,items},
  {selected,color,width_dependent:{space},space_top,group_space,text_w},
  {visual_columns}) => {
  const to_highlight = x => C(
    includes(x.id),uniq,flatten,
    juxt(m(C(
      repeat_fn(3),
      ([by,key]) => keys => C(
        f(id),concat(keys),uniq,m(p(key)),
        f(C(includes(__,keys),p(by)))
      )(connections)
    ),[["from","to"],["to","from"]])),
    of,p("id")
  )(selected)
  return C(
    chain((val) => val.map((x,n) => {
      const is_grey = !x.connected && x.type !== "actions";
      const selection = x.id === selected.id;
      const dx = indexBy(p("column"),visual_columns)[x.type].x;
      const dot_colors = ([inactive,selected,other]) => is_grey ? color[inactive] : 
        to_highlight(x) ? color[selected] :  color[other];
      const y = r(1, space_top + (n+x.g_spaces)*space + x.group_n*group_space + 
        ((selected.type === x.type && selected.field === x.field && selected.n < n) ? 10 : 0));
      return {...x,n,x:dx,y,
        highlighted: to_highlight(x),
        dot_fill:dot_colors(["white", "highlight","light_grey"]),
        dot_stroke:dot_colors(["grey", "highlight","grey"]),
        font_weight:x.desc || x.comment ? 700 : 400,
        text_fill:is_grey ? color.grey : selection ?  color.highlight : color.black,
        font_size : selection ? 14 : 12,
        text_x: dx + text_w/2,
        text_y: y+3}
    })),
    values,
    g(p("type"))
  )(items)
}

export const active = ({},{selected,opened:[open_columns],text_w},{points}) => C(
  f(p("id")),
  m(d => {
    const point_pos = points.find(x => x.id === d.id);
    return {id: d.id,y: p("y",point_pos)+7,
    x: p("x",point_pos)+(includes(d.type,open_columns) ? text_w/2 : 0) - 7}}),
  of
)(selected)


export const bars = ({},{width_dependent:{score_w,width,margin},breakpoint,color,selected},{points}) => 
  width <= breakpoint ? C(
    concat([[],[]]),of,
    m(d => ({...d,y:d.y+3,
      x:d.x-score_w,
      fill:(!d.connected && d.type !== "actions") ? color.grey :  
        d.id === selected.id ?  color.highlight : color.black })),
    problems
  )(points)  : C(
    append([]),
    juxt([head,C(m(d => ({...d, d:rounded_rect(d.x,d.y,3,12,2,d.corners)})),last)]),
    partition(propEq("corners","rect")),
    chain(x => addIndex(m)((a,n) => ({
      y:a.y-6,
      x:margin+n*4+(n>21 ? 2 : 0), 
      id: x.id+"_"+n+1,
      corners: includes(n+1,[1,23]) ? [1,0,1,0] : 
              includes(n+1,[22,25]) ? [0,1,0,1] : "rect",
      fill: !(((n < 22) ? a.ilma_erata : a.era+22) >= (n+1)) ? color.mid_grey :
        x.connected ?  color.highlight : color.grey,
    }),repeat(x,25))),
    problems
  )(points);


export const groups = ({},{width_dependent:{space,margin},color},{points}) => C(
  m(([key,val]) => ({id:key,...val})),
  toPairs,
  addIndex(m)((y,n) => ({n,y,fill:color.blue,x:margin})),
  m(C(
    subtract(__,space*1.2),
    apply(Math.min),m(p("y")),
    problems)),
  g(p("field"))
)(points)


export const texts = ({},{opened:[open_columns]},{points}) => 
  f(C(includes(__,open_columns),p("type")),points)


export const links = ({connections=[]},{color,width_dependent:{line_w}},{points,visual_columns}) => {
  const y_pos = indexBy(p("id"),points);
  const x_pos = indexBy(p("column"),visual_columns);
  const inactive = x => y_pos[x.to].connected || y_pos[x.to].type === "actions";
  return chain(([key,n]) => C(
      sort(ascend(p("greyed"))),
      f(p("highlighted")),
      m(C(
        x => ({...x,d: create_path(30,25)(x)}),
        x => ({...x,id:x.from + "_" + x.to,
          greyed: inactive(x),
          highlighted: y_pos[x.from].highlighted && y_pos[x.to].highlighted, 
          stroke:inactive(x) ?  color.highlight : color.grey,
          stroke_w:inactive(x) ?  3 : 1,
          x1:x_pos[key].x-line_w,
          x2:x_pos[key].x,
          y1:y_pos[x.from].y,
          y2:y_pos[x.to].y}))),
      f(propEq("type",n))
    )(connections),
  [["solutions",1],
   ["actions",2]])
}
