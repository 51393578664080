import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { compose as C, concat, isNil, join, map as m, reject, toPairs, __, splitAt, clamp, apply, prop as p } from 'ramda';
import { initial } from './initial';
import { supabaseApi } from './api';

const set = key => (state,{payload}) => {state[key] = payload}

const uiSlice = createSlice({
  name: 'ui',
  initialState:initial,
  reducers: {
    set_info_open: (state,{payload}) => {state.info_open = payload !== undefined ? payload : !state.info_open},
    set_panel_open: (state,{payload}) => {state.panel_open = payload !== undefined ? payload : !state.panel_open},
    set_width_dependent: (state) => {state.width_dependent = width_dependent(state)},
    set_opened: (state,{payload}) => {state.opened = payload || init_opened(state)},
    set_space_top: (state,{payload}) => {state.space_top = state.opened[0].length > 3 ? 85 : 155},
    set_selected: set("selected"),
    set_priority: set("priority"),
    set_url: set("url"),
  },
})
export const {set_panel_open,set_url,set_width_dependent,set_opened,set_selected,set_priority,
  set_space_top,set_info_open} = uiSlice.actions

export default uiSlice.reducer

const width_dependent = ({text_w,breakpoint,panel_open}) =>  {
  const w = document.body.clientWidth;
  const width = w - ((panel_open && w > 1000) ? 560 : 20);
  const [score_w,line_w,space,margin] = width > breakpoint ? [120,40,20,30] : [30,20,25,10];
  const w_mode = C(clamp(1,4),Math.floor)((width - 2*margin - 3*line_w-score_w) / text_w);
  return {score_w,width,line_w,space,w_mode,margin}}

const init_opened = ({width_dependent:{w_mode},priority}) => 
  splitAt(w_mode,priority)

export const toggle_panel = (open) => (dispatch,getState) => {
  dispatch(set_panel_open(open));
  dispatch(resize_window());
}
export const resize_window = () => (dispatch) => {
  dispatch(set_width_dependent());
  dispatch(toggle_columns());
}
export const toggle_columns = (opened) => (dispatch) => {
  dispatch(set_opened(opened));
  dispatch(set_space_top());
}

export const initialize_state = createAsyncThunk("load_data",async (_,{dispatch,getState}) => {
  dispatch(resize_window())
  dispatch(supabaseApi.endpoints.items.initiate());
  dispatch(supabaseApi.endpoints.connections.initiate());
  dispatch(supabaseApi.endpoints.columns.initiate());
  dispatch(supabaseApi.endpoints.criterias.initiate());
}); 

const load_url = () => (dispatch,getState) =>{
  const url = new URLSearchParams(window.location.search.slice(1));
/*   const {conf} = getState();
  const params = [
      ["selected",set_selected,C(of,split(","))],
      ["columns",set_opened,of]];
  forEach(([x,fn,prep]) => {const val = url.get(x);if(val) dispatch(apply(fn,prep(val)))},params); */
}

const update_url = (val) => (dispatch,getState) => {
  const {url} = getState();
  dispatch(set_url({...url,...val}))
  const params = C(concat("?"),join("&"),m(join("=")),toPairs,reject(isNil))({...url,...val})
  window.history.replaceState(null,null,params); 
}