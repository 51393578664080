import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { supabaseApi } from './api';
import ui_state from './ui_state';

export const store = configureStore({
  reducer: {
    ui: ui_state,
    [supabaseApi.reducerPath]: supabaseApi.reducer,
  },
  middleware: middleware =>  middleware().concat(supabaseApi.middleware),
})

setupListeners(store.dispatch)