import { path } from "ramda";
import { useCriteriasQuery } from "../../app/api";
import "./infobox.css"
import { useDispatch, useSelector } from "react-redux";
import { set_info_open } from "../../app/ui_state";
import { Instrument } from "../instrument/instrument";

export const InfoBox = () => {
    const dispatch = useDispatch();
    const info_open = useSelector(path(["ui","info_open"]));
    const {data: explanation} = useCriteriasQuery();

    return <div style={{display:"inline-block",verticalAlign:"center"}} id="information">
        <svg onClick={() => dispatch(set_info_open())} width="25" height="25" 
            viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M28 56C43.464 56 56 43.4639 56 28C56 12.5361 43.464 0 28 0C12.536 0 0 12.5361 0 28C0 43.4639 12.536 56 28 56ZM22.375 39.4648V43.4688H34.1719V39.4648L31.7109 38.8398V22.3359H21.9062V26.3594L24.8555 26.9844V38.8398L22.375 39.4648ZM24.6602 13V18.332H31.7109V13H24.6602Z" fill="#E0E6EE"/>
        </svg>
        {info_open ? <div class="bg-close" onClick={() => dispatch(set_info_open(false))}></div> : ""}
        <div className={`infobox ${info_open ? "opened" : ""}`}>
            <div>
                <h2>Mis leht see on?</h2>
                <p>See leht on mõeldud esmajoones ühiskondlikele investoritele ja eraannetajatele, kes soovivad teha teadmispõhiseid otsuseid oma toetustegevuste kujundamisel. 2023. aasta alguses pöördus mõttekoja Praxis poole Taavet+Sten Tulevikufond küsimusega, milliste probleemide lahendamisel Eesti hariduse ja vaimse tervise valdkondades ning milliste tegevuste toetamisega saaksid nemad ja teised erasektori esindajad investoritena tuua suurimat ühiskondlikku kasu.</p>
                <p>Vastuse otsimisel võtsime aluseks efektiivse altruismi liikumise ning sotsiaalsete investeeringute lähenemise põhimõtted. Efektiivse altruismi liikumine ühendab teadlasi ja praktikuid, keda huvitavad küsimused, milliste tegevustega – olgu rahalise toetuse, karjäärivaliku või ka kõige igapäevasemate käitumisvalikutega – saame teha võimalikult palju head, vähendades inimeste kannatusi ja suurendades heaolu. Sotsiaalsete investeeringute lähenemise keskmes on arusaam inimkapitali arendamisest ja olemasoleva inimkapitali maksimaalselt heast kasutamisest kui sammudest, mis on vajalikud majandusarengu ja tööhõive kasvuks – erinevalt näiteks neoliberaalsest vaatest, mis käsitleb sotsiaalpoliitikat pigem kuluna.</p>
                <p>Nendest põhimõtetest lähtudes koostasime 9 küsimusega tööriista, mille abil reastada valdkondlikke probleeme nende olulisuse järgi. Koostöös tellijaga valisime seejärel nii hariduse kui ka vaimse tervise valdkonna probleemidest välja kummastki kolm, millele kaardistasime võimalikke lahendusi, sh Eestis ja mujal rakendatud sekkumisi.</p>
                <p>Leht täieneb vastavalt meie ühiskondlike investorite huvile. Lisaks juba kirjeldatud lahendustega probleemidele saame kaardistada lahendusi nii Eestist kui ka mujalt ka kummagi valdkonna teistele probleemidele. Samuti saab sama tööriista abil reastada probleeme ja lahendusi ka teistes valdkondades, olgu nendeks näiteks inimeste toimetulekuraskused, siseturvalisus või muu, mille lahendamisele kaasa aidata soovitakse. Võta huvi korral ühendust Praxise juhataja Urmo Kübaraga:<a href="mailto:urmo.kybar@praxis.ee"> urmo.kybar@praxis.ee.</a></p>
                <br />
                <h3>Probleemide hindamise tööriist</h3>
                <p>Et valdkondlikke probleeme nende olulisuse põhjal järjestada, esitasime nende kohta 9 küsimust, millest igaüht hindasime 3-punktisel skaalal. Erandiks oli küsimus, kui varases eluetapis probleem inimest puudutab – kuna sotsiaalsete investeeringute paradigma rõhutab sekkumisi inimese võimalikult varases elueas, siis said esimese 15 eluaasta jooksul inimese elu mõjutavad probleemid siin 4 punkti.</p>
                <Instrument data={explanation || []} />
            </div>
        </div>
    </div>;
}
