import {prop as p, toUpper, compose as C, length as len, multiply} from "ramda";
import * as d3 from "d3"
import { transition } from "../helpers";

export const text_pos = x => x
  .attr("x", p("text_x"))
  .attr("y", p("text_y"))
  .attr("fill",p("text_fill"))
  .style("opacity",1) 
  .attr("font-size", p("font_size"))  
export const add_text = x => x
  .attr("font-weight", p("font_weight")) 
  .attr("text-anchor","middle")
  .attr("cursor", "pointer")
  .text(p("name"))
  .call(text_pos)

export const dot_pos = x => x
  .attr('cy', p("y"))
  .attr('cx', p("x"))
  .attr('fill', p("dot_fill"))
  .attr("stroke",p("dot_stroke"))
export const add_dot = x => x
  .attr('r', 5) 
  .call(dot_pos)

export const change_line = x => x
  .attr("d",p("d")); 
export const add_line = x => x
  .attr("fill", "none")
  .attr("stroke-width",p("stroke_w"))
  .attr("stroke", p("stroke"))
  .call(change_line)

export const title_move = x => x
  .attr("y", p("y"))
export const add_title = x => x
  .attr("x", p("x")) 
  .attr("font-size", 20) 
  .attr("font-weight",900) 
  .attr("text-anchor","left")
  .attr("fill", p("fill"))  
  .text(C(toUpper,p("id"))) 
  .call(title_move)
export const title_pos = transition(title_move,0);

export const move_title_bg = x => x
  .attr("y", x => x.y-17)
export const title_bg = x => x
  .attr("x", p("x")) 
  .attr("height", 22) 
  .attr("width",C(multiply(14),len,p("id"))) 
  .attr("fill", "white")   
  .call(move_title_bg)

export const marker_move = x => x
    .attr("transform", d => "translate(" + d.x + "," + (d.y) + ")")  
export const add_marker = x => x
  .attr("xlink:href","#chevron")
  .call(marker_move)
export const marker_pos = transition(marker_move,0)

export const move_header = x => x
  .attr("transform", p("transform"));
export const add_header = x => x
  .attr("dx", 0)
  .attr("dy", 0)
  .attr("font-size", 14) 
  .attr("font-weight",900) 
  .attr("text-anchor","middle")
  .text(C(toUpper,p("name"))) 
  .attr("fill", p("fill")) 
  .attr("cursor", "pointer")
  .call(move_header)

export const end_bars = x => x
  .attr("d",p("d"))
  .attr("fill",p("fill"))
export const bar_pos = x => x
  .attr("y",p("y"))
export const add_bars = x => x
  .attr("x",p("x"))
  .attr("width",3) 
  .attr("height",12) 
  .attr("fill",p("fill"))
  .call(bar_pos)
export const score_pos = x => x
  .attr("x", p("x"))
  .attr("y", p("y"))
export const add_scores = x => x 
  .attr("fill",p("fill"))
  .attr("font-size", 12) 
  .attr("font-weight",700) 
  .text(p("skoor"))
  .call(score_pos)

