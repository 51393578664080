import React, { useEffect, useState } from 'react';
import { supabase } from './app/api';
import Auth from './app/Auth';
import Account from './app/Account';
import { createContext } from 'react';
import { Visual } from "./features/visual/visual";
import { initialize_state, resize_window, set_width_dependent } from './app/ui_state';
import { useDispatch } from 'react-redux';
import { debounce } from './features/helpers';
import { DetailsPane } from './features/detailspane/pane';
import { InfoBox } from './features/info/infobox';
import "./App.css"

export const UserContext = createContext(); 

const App = () => {
  const [session, setSession] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => { setSession(session); });
    supabase.auth.onAuthStateChange((_event, session) => { setSession(session); });
    dispatch(initialize_state());
    window.addEventListener("resize",debounce(() => {dispatch(resize_window())}))
  }, []);

  return <>
    <UserContext.Provider value={session}>
      <main id="main">
        <header>
          <h1 id="page_title">Sotsiaalsed investeeringud</h1>
          <InfoBox/>
        </header>
        <Visual/>
        <DetailsPane/>
      </main>
    </UserContext.Provider>
  </>;
};

export default App;
