import { createClient } from "@supabase/supabase-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } from "./constants";
import { compose as C, apply, invoker, map as m, unapply, __, filter as f} from "ramda";
import { group_spacers } from "./functions";

export const supabase = createClient(
  REACT_APP_SUPABASE_URL,
  REACT_APP_SUPABASE_KEY, 
  {db:{schema:'social_invest'}});

const invokeAll = (xs,data) => C(...m(
  apply(unapply(([method, ...args]) => invoker(args.length, method)(...args))),
  xs))(data);

export const supabaseApi = createApi({
    baseQuery: fakeBaseQuery(),
    tagTypes: ["items","connections","columns","criterias"],
    endpoints: build => ({
      items: build.query({
        providesTags: (result) => ['items'],
        queryFn: async (id,{dispatch}) => {
          const response = await invokeAll(
            [['select',"*"],['from',"items_joined"]],supabase);
          const spacers = group_spacers({items:response.data}) || [];
          return {...response,data:m(x => ({...x, g_spaces:spacers[x.field][x.type]}),response.data)};
        }}),
      connections: build.query({
        providesTags: ["connections"],
        queryFn: async (id,{dispatch}) => {
          const response = await invokeAll(
            [['select',"id,type,from:from_id, to:to_id"],['from',"typed_connections"]],supabase);
          return response;
        }
      }),
      columns: build.query({
        providesTags: ["columns"],
        queryFn: async (id,{dispatch}) => {
          const response = await invokeAll(
            [['select',"*"],['from',"types"]],supabase);
          return response;
        }
      }),
      criterias: build.query({
        providesTags: ["criterias"],
        queryFn: async (id,{dispatch}) => {
          const response = await invokeAll(
            [['select',"*"],['from',"criterias_extra"]],supabase);
          return response;
        }
      }),
/*       upsertData: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Leheosad').upsert(obj).select();
          return result},
        invalidatesTags: ["praxis"],
      }),
      upsertConnections: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Projektid').update(omit(["id"],obj)).eq("id",obj.id).select();
          return result},
        invalidatesTags: ["project","projects"],
      }), */
    })
})
export const {
  useItemsQuery,
  useConnectionsQuery,
  useColumnsQuery,
  useCriteriasQuery
} = supabaseApi;