import * as d3 from "d3"
import {compose as C, head, sort, ascend, last, toPairs, countBy,identity as id,curry, path, filter as f, defaultTo,
} from "ramda";

const duration = 200;

export const r = curry((pow,x) => Math.round(x*Math.pow(10,pow))/Math.pow(10,pow));
export const most_common = C(head,head,sort(ascend(last)),toPairs,countBy(id));
export const repeat_fn = curry((n,fn) => C(...Array(n).fill(fn)))

export const appear = (fn,delay) => x => x.call(fn).style("opacity",0)
  .transition().ease(d3.easeQuadIn).delay(delay)
  .duration(duration).style("opacity",1);

export const transition = (fn,delay,on_end) => x => x
  .transition().ease(d3.easeQuad).delay(delay)
  .duration(duration).call(fn).call(on_end || id);

export const disappear = fn => x => x
  .transition().ease(d3.easeQuadOut)
  .duration(duration).call(fn).style("opacity",0).remove();

export const create = (svg) => 
  ({id,el,data,add = d => d,edit = d => d,remove = d => d.remove()}) => 
    svg.select(id).selectAll(el).data(data,d => d.id)
      .join(d => d.append(el).call(add),d => d.call(edit),d => d.call(remove))

export const add_clickevent = svg => ({id,el,click}) => 
  svg.select(id).selectAll(el).on("click", click);

export const debounce = (fn, timeout = 200) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {fn.apply(this, args)}, timeout)}}

export const create_path = (r,p) => ({x1,y1,x2,y2,mid}) => {
  const half = mid || ((x2-x1)/2),
    c = x1 + half,
    c_w = half * r/100,
    c_r = c_w * p/100,
    dir = y1 === y2 ? "straight" : y2 > y1 ? "down" : "up";
  return {
    down :`M${x1} ${y1}H${c - c_w
      }C${c - c_r} ${y1} ${c} ${y1 + c_r} ${c} ${y1 + c_w}V${y2 - c_w
      }C${c} ${y2 - c_r} ${c + c_r} ${y2} ${c + c_w} ${y2}H${x2}`,
    up:`M${x1} ${y1}H${c - c_w
      }C${c + c_r} ${y1} ${c} ${y1 - c_r} ${c} ${y1 - c_w}V${y2 + c_w
      }C${c} ${y2 + c_r} ${c + c_r} ${y2} ${c + c_w} ${y2}H${x2}`,
    straight: `M${x1} ${y1}H${x2}`
  }[dir]
}

export const rounded_rect = (x, y, w, h, r, [tl, tr, bl, br]) => {
  const corner = (is_round) => (t,l) => is_round ? 
    `a${r},${r} 0 0 1 ${(t?1:-1)*r},${(l?-1:1)*r}` : 
    (((t!==l)?"h":"v") + (l?-1:1)*r + ((t!==l)?"v":"h") + (t?1:-1)*r);
  return `M${x + r},${y
    }h${w-2*r}${corner(tr)(1,0)
    }v${h-2*r}${corner(br)(0,0)
    }h${2*r-w}${corner(bl)(0,1)
    }v${2*r-h}${corner(tl)(1,1)
    }z`
}
