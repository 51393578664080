import { useDispatch, useSelector } from "react-redux";
import { __, path, identity as id} from "ramda";
import "./pane.css"
import { toggle_panel } from "../../app/ui_state";

export const DetailsPane = () => {
  const dispatch = useDispatch();
  const selected = useSelector(path(["ui","selected"]));
  const open = useSelector(path(["ui","panel_open"]));
  return !selected ? "" : <div style={{transform: `translateX(${open ? 0 : "120%"})`}} id="detailspane">
        <div>
            <svg onClick={() => {dispatch(toggle_panel(false))}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 56 56" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56 28C56 43.4639 43.464 56 28 56C12.536 56 0 43.4639 0 28C0 12.5361 12.536 0 28 0C43.464 0 56 12.5361 56 28ZM14 18.7803L18.7805 14L28 23.2197L37.2195 14L42 18.7803L32.7805 28L42 37.2197L37.2195 42L28 32.7803L18.7805 42L14 37.2197L23.2195 28L14 18.7803Z" fill="#E0E6EE"/>
            </svg>
            <h2>{selected.longer_name || selected.name}</h2>
            {selected.desc ? 
                <p>{selected.desc}</p> : 
                ""
            }    
            {selected.comment ? 
                <>
                <p className="title">fookusrühma kommentaar:</p>
                <p>{selected.comment}</p>
                </> : 
                ""
            }    
        </div>
  </div>
};
