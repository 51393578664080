import { compose as C, ascend, groupBy as g, map as m, objOf, prop as p, sort, values, head, mergeRight, juxt, apply, omit } from "ramda";
import "./instrument.css"
export const Instrument = ({data,score,answer}) => C(
    instrument => <div className="instrument">
        {m(x =>
            <div className="score">
                <span>{x.name}</span>
                <div>{m(b => <div className="bar"></div>,score || Array(x.answers.length).fill(1))}</div>
                <ol>{m(b => <li className="answer">{b}</li>,answer || x.answers)}</ol>
            </div>,instrument)}
        </div>,
    sort(ascend(p("id"))),
    values,
    m(C(
        apply(mergeRight),
        juxt([
            C(omit(["text","score"]),head),
            C(objOf("answers"),(m(p("text"))))
        ])
    )),
    g(p("id"))
)(data)
