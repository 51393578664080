export const initial = {
    text_w: 250,
    group_space: 60,
    breakpoint: 550,
    priority: ["problems","orgs","actions","solutions"],
    space_top: 220,
    color: {
        highlight: "#C06817",
        grey: "#8496B0",
        mid_grey: "#D6DCE4",
        light_grey: "#E0E6EE",
        blue:"#1620A3",
        black: "black",
        white: "white",
    },
    url: {},
    panel_open: false,
    width_dependent: {
        margin: 0,
        width:400,
        score_w:30,
        line_w:20,
        space: 25,
        w_mode:1
    },
    info_open: false,
    opened: [["problems"],["solutions","actions"]],
    selected: {},
}